import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src430903205/src/dashbot-home-gatsby/src/layouts/blog/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Think beyond the screen.`}</h2>
    <p>{`We’ve talked to many developers and brands about their adventures building for voice interfaces such as Amazon Alexa and Google Home. Everyone always asks what team they should hire and what technology should they invest in, but they need to think about how to engage a user in a completely different interface.`}</p>
    <p>{`Here are 6 things to think about as you build voice apps:`}</p>
    <h2>{`Write out the conversation and say it out loud.`}</h2>
    <p>{`Yes, using pen and paper. Think about writing the conversation as prototyping for voice apps.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Interactions with bots should be natural and the only way to design a conversation that sounds natural to the user is to actually write the words down and say them out loud.`}</p>
    </blockquote>
    <p>{`However, writing it down is not enough. What looks good on paper is often too long or rigid in reality and being concise is important. When you have someone act as the device reading the responses out loud, you’ll begin to hear the cadence and tone of your script.`}</p>
    <h2>{`Keep it simple.`}</h2>
    <p>{`Prioritizing the user is one of the most important things to think about as you build on voice platforms, or on any platform. Because this space is new and users are bringing devices into their homes, you want to make sure the user’s first impression of your voice app is a memorable one. Memorable enough so that users will invoke your app over and over while continuing to stay engaged each time.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Make sure the name of your app is easy to pronounce, simple to remember, and relates to what it can accomplish.`}</p>
    </blockquote>
    <h2>{`Pave the user pathway.`}</h2>
    <p>{`Understand the platform you’re building on and don’t let any limitations constrain the features of the voice experience. Dave Witting, Partner at `}<a parentName="p" {...{
        "href": "http://www.rocketinsights.com/about/"
      }}>{`Rocket Insights`}</a>{`, makes a notable point about taking advantage of refreshing the experience in order to introduce new features in each interaction. The team at Rocket Insights added easter eggs to their Skills that then went viral on Reddit.`}</p>
    <p>{`On the other end of the design spectrum is giving users too much free range to explore on their own. You can prevent excessive fallback responses by refining the requests your voice app handles and making sure the ones that it can answer, it answers well. Don’t allow for open-ended requests that result in frustrating fallbacks because that will drive users away.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Consumers are just starting to learn and understand what voice technology can do and if all they get is a dead-end, they’re not going to make the effort to come back so make sure you guide them where you want.`}</p>
    </blockquote>
    <h2>{`Leverage first-mover advantage.`}</h2>
    <p>{`I’m sure you’ve heard someone say, “There’s an app for that!” Well, that doesn’t apply to voice technology. There are over 24,000 Alexa Skills on the Amazon Directory and many verticals are waiting to be conquered. Music and weather are popular go-to’s, but what about voice apps for cooking, travel, or healthcare?`}</p>
    <blockquote>
      <p parentName="blockquote">{`Be the first to build it and you’ll most likely be listed at the top of the category where users can find your voice app easily.`}</p>
    </blockquote>
    <h2>{`Deliver the solution you promise.`}</h2>
    <blockquote>
      <p parentName="blockquote">{`If you’re extending a large brand across voice platforms, you need to remember that users have predefined expectations about what solutions your voice app can provide.`}</p>
    </blockquote>
    <p>{`If you’re a food delivery company that is interested in adding voice tech to your various levers, you need to be able to guarantee the same seamless ordering and delivery experience. With voice devices, there are no screens for users to enter their credit card numbers or scroll through a long list of menu items. How will you help them achieve the same food ordering success through a different user experience?`}</p>
    <h2>{`Don’t forget to sprinkle in personality.`}</h2>
    <blockquote>
      <p parentName="blockquote">{`If Coca Cola were a person, how would you describe him or her? What would Coca Cola sound like? What kind of humor would this person have? Be vigilant about setting high product standards for voice apps when defining what is and isn’t “on-brand.” `}<em parentName="p">{`Coca Cola would never say that.`}</em></p>
    </blockquote>
    <p>{`Most importantly, think critically about what a user expects from your current product and how that would translate audibly. Another way to approach this is similar to how designers use a palette for website colors — you’d never use something that wasn’t part of brand guidelines, right? It’s not that black and white though because this is a new interface and you get to surprise the user in a completely different medium.`}</p>
    <hr></hr>
    <h2>{`About Dashbot`}</h2>
    <p><a parentName="p" {...{
        "href": "http://www.dashbot.io/?utm_medium=blog_voicedesign"
      }}><em parentName="a">{`Dashbot`}</em></a>{` `}<em parentName="p">{`is a conversational analytics platform that enables developers and brands to increase engagement, acquisition, and monetization through actionable data and tools. We provide `}<a parentName="em" {...{
          "href": "https://voice.dashbot.io/?utm_medium=blog_voicedesign"
        }}>{`Voice specific analytics`}</a>{` for Amazon Alexa and Google Home.`}</em></p>
    <p><em parentName="p">{`In addition to traditional analytics like engagement and retention, we provide chatbot specific metrics including sentiment analysis, conversational analytics, and the full chat session transcripts.`}</em></p>
    <p><em parentName="p">{`We also have tools to take action on the data, like our live person take over of sessions and push notifications for re-engagement.`}</em></p>
    <p><a parentName="p" {...{
        "href": "https://www.dashbot.io/contact/sales?ref="
      }}>{`Contact us for a demo`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      